@font-face {
  font-family: "Cormorant";
  font-weight: 300;
  src: url("../fonts/Cormorant/Cormorant-Light.ttf") format("truetype"), url("../fonts/Cormorant/Cormorant-Light.woff") format("woff");
}
@font-face {
  font-family: "Cormorant";
  font-weight: 400;
  src: url("../fonts/Cormorant/Cormorant-Regular.ttf") format("truetype"), url("../fonts/Cormorant/Cormorant-Regular.woff") format("woff");
}
@font-face {
  font-family: "Cormorant";
  font-weight: 500;
  src: url("../fonts/Cormorant/Cormorant-Medium.ttf") format("truetype"), url("../fonts/Cormorant/Cormorant-Medium.woff") format("woff");
}
@font-face {
  font-family: "Cormorant";
  font-weight: 600;
  src: url("../fonts/Cormorant/Cormorant-SemiBold.ttf") format("truetype"), url("../fonts/Cormorant/Cormorant-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Cormorant";
  font-weight: 700;
  src: url("../fonts/Cormorant/Cormorant-Bold.ttf") format("truetype"), url("../fonts/Cormorant/Cormorant-Bold.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  src: url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Light.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff");
}
:root {
  --background-color: #FBFBFB;
  --surface-color: #FFFFFF;
  --primary-color: #C2A177;
  --on-primary-color: #FFFFFF;
  --outline-color: #A6A6A6;
  --secondary-color: #4B4B4B;
  --tertiary-color: #000000;
  --shadow-color: rgba(0, 0, 0, 0.1);
}
:root {
  --title-font-family: 'Cormorant';
  --text-font-family: 'Montserrat';
}
p,
h2,
h1,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}
p {
  font-family: var(--text-font-family);
}
h1,
.DisplayLarge {
  font-family: var(--title-font-family);
  color: var(--primary-color);
  font-size: 64px;
  font-weight: 700;
}
h2,
.DisplayMedium {
  font-family: var(--title-font-family);
  color: var(--primary-color);
  font-size: 76px;
  font-weight: 700;
}
h3,
.DisplaySmall {
  font-family: var(--title-font-family);
  color: var(--primary-color);
  font-size: 40px;
  font-weight: 700;
}
h4,
.HeadlineLarge {
  color: var(--secondary-color);
  font-size: 25px;
  font-weight: 700;
}
h5,
.HeadlineMedium {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 500;
}
h6,
.HeadlineSmall {
  color: var(--on-primary-color);
  font-size: 18px;
  font-weight: 600;
}
.TitleLarge {
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: 500;
}
.TitleMedium {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
}
.TitleSmall {
  color: var(--secondary-color);
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
}
.BodyMedium {
  color: var(--secondary-color);
  font-size: 18px;
  font-weight: 600;
}
.BodySmall {
  color: var(--tertiary-color);
  font-size: 16px;
  font-weight: 500;
}
.LabelMedium {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
}
.LabelSmall {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
}
.Mobile h1,
.Mobile .DisplayLarge {
  font-size: 40px;
}
.Mobile h2,
.Mobile .DisplayMedium {
  font-size: 28px;
}
.Mobile h3,
.Mobile .DisplaySmall {
  font-size: 25px;
}
.Mobile h4,
.Mobile .HeadlineLarge {
  font-size: 14px;
}
.Mobile h5,
.Mobile .HeadlineMedium {
  font-size: 14px;
}
.Mobile h6,
.Mobile .HeadlineSmall {
  font-size: 14px;
}
.Mobile .TitleLarge {
  font-size: 20px;
}
.Mobile .TitleMedium {
  font-size: 14px;
}
.Mobile .TitleSmall {
  font-size: 12px;
}
.Mobile .BodyMedium {
  font-size: 12px;
}
.Mobile .BodySmall {
  font-size: 10px;
}
.Mobile .LabelMedium {
  font-size: 16px;
}
.Mobile .LabelSmall {
  font-size: 10px;
}
html,
body {
  margin: 0;
  background-color: var(--background-color);
}
.PlatformOffset {
  display: flex;
  justify-content: center;
  width: 1140px;
  align-self: center;
}
.Mobile .PlatformOffset {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
header,
.StartBlock,
.BlockCard {
  display: flex;
  width: 100%;
  justify-content: center;
}
.CatalogBlock,
.HowUseBlock,
.SeamlessCoatingCalculatorBlock,
.ContactInfo {
  scroll-margin-top: 72px;
}
.Mobile .CatalogBlock,
.Mobile .HowUseBlock,
.Mobile .SeamlessCoatingCalculatorBlock,
.Mobile .ContactInfo {
  scroll-margin-top: 48px;
}
header {
  position: sticky;
  top: 0;
  background-color: var(--surface-color);
  box-shadow: 0px -4px 30px 0px var(--shadow-color);
}
.HeaderContent {
  display: flex;
  justify-content: space-around;
  height: fit-content;
  align-items: end;
  padding: 14px 0px;
}
.Mobile .HeaderContent {
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.Logo {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.Logo .DisplaySmall {
  font-size: 32px;
}
.LightLogo .DisplaySmall {
  color: var(--on-primary-color);
}
.Mobile .Logo .DisplaySmall {
  font-size: 25px;
}
button {
  height: fit-content;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
button.Touched {
  opacity: 0.75;
}
button a {
  text-decoration: none;
}
.SectionButton {
  display: flex;
  align-items: flex-end;
}
.SectionButton .TitleMedium {
  font-weight: 500;
  line-height: 39px;
}
.StartBlockContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  justify-content: space-between;
  background: right center no-repeat url('../assets/images/tiles.png');
  background-size: 400px 400px;
}
.StartBlockContent .DisplayMedium {
  margin-bottom: 40px;
}
.StartBlockContent .TitleMedium {
  margin-bottom: 55px;
}
.StartBlockContent .LabelMedium {
  margin-top: 20px;
  margin-bottom: 20px;
}
.Mobile .StartBlockContent {
  flex-direction: column;
  background: none;
  margin-top: 30px;
}
.Mobile .StartBlockContent .DisplayMedium {
  margin-bottom: 15px;
}
.Mobile .StartBlockContent .TitleMedium {
  margin-bottom: 10px;
}
.Mobile .StartBlockContent .FilledButton {
  margin-bottom: 20px;
}
.Mobile .StartBlockContent .LabelMedium {
  margin-top: 0;
  margin-bottom: 0;
}
.LeftLine {
  width: 3px;
  min-width: 3px;
  height: 365px;
  margin-top: 26px;
  margin-left: 5px;
  margin-right: 75px;
  background-color: var(--primary-color);
  border-radius: 5px;
}
.Mobile .LeftLine {
  height: 220px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0;
}
.RightLine {
  width: 3px;
  min-width: 3px;
  height: 150px;
  background-color: var(--primary-color);
  border-radius: 5px 5px 0px 0px;
  align-self: center;
}
.Mobile .RightLine {
  height: 80px;
  margin-left: 0px;
}
.FilledButton {
  border-radius: 5px;
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color);
  padding: 15px 30px;
}
.FilledButton.SecondaryButton {
  background-color: transparent;
}
.FilledButton.SecondaryButton .HeadlineSmall {
  color: var(--primary-color);
}
.Mobile .FilledButton {
  width: 100%;
}
.ButtonBar {
  display: flex;
  flex-direction: row;
}
.ButtonBar .FilledButton:first-child {
  margin-right: 40px;
}
.RightLineView {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-end;
  margin-right: 5px;
}
.Mobile .RightLineView {
  margin-right: 0;
}
.TilesImagesView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.CatalogBlock {
  background-color: var(--background-color);
}
.CatalogBlock .BlockCard {
  background-color: var(--surface-color);
}
.BlockCard {
  border-radius: 30px 30px 0px 0px;
}
.BlockCardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 55px 65px 55px 83px;
}
.BlockCardContent .DisplayLarge {
  margin-bottom: 40px;
}
.Mobile .BlockCardContent {
  padding: 30px 0px 30px 0px;
}
.Mobile .BlockCardContent .DisplayLarge {
  margin-bottom: 20px;
}
.CatalogsItemsView {
  display: flex;
  flex-direction: column;
}
.Mobile .CatalogsItemsView {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 30px;
}
.CatalogItem {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: space-around;
}
.Mobile .CatalogItem {
  flex-direction: column;
  margin-top: 30px;
  justify-content: flex-start;
}
.CatalogItemImage {
  margin-right: 20px;
}
.Mobile .CatalogItemImage {
  margin-right: 0;
  margin-bottom: 20px;
}
.CatalogItemInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: center;
}
.CatalogItemInfo .BodyMedium:first-child,
.CatalogItemInfo .BodyMedium:not(:first-child) {
  margin-bottom: 15px;
}
.CatalogItemInfo .BodyMedium:first-child {
  color: var(--outline-color);
}
.CatalogItemInfo .BodyMedium:not(:first-child) {
  font-weight: 500;
}
.CatalogItemInfo .TitleMedium {
  color: var(--secondary-color);
  margin-bottom: 15px;
}
.Mobile .CatalogItemInfo .BodyMedium:first-child,
.Mobile .CatalogItemInfo .BodyMedium:not(:first-child) {
  margin-bottom: 10px;
}
.Mobile .CatalogItemInfo .TitleMedium {
  margin-bottom: 10px;
}
.Mobile .CatalogItemInfo .ColorsPlaceholder {
  height: 25px;
}
.CatalogItemPriceView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}
.CatalogItemPriceView .HeadlineLarge {
  margin-bottom: 30px;
  text-align: center;
}
.Mobile .CatalogItemPriceView {
  margin-left: 0;
}
.Mobile .CatalogItemPriceView .HeadlineLarge {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.WarningBlock {
  background-color: var(--surface-color);
}
.WarningBlock .BlockCard {
  background-color: var(--primary-color);
}
.WarningBlock .DisplayLarge {
  margin-bottom: 0;
  color: var(--on-primary-color);
}
.WarningBlock .BlockCardContent {
  padding-top: 15px;
  padding-bottom: 30px;
}
.WarningBlock .TitleMedium {
  color: var(--on-primary-color);
  line-height: 1.5;
}
.WarningBlock .TitleMedium a {
  text-decoration: underline;
}
.Mobile .WarningBlock .DisplayLarge {
  margin-bottom: 0;
}
.HowUseBlock {
  background-color: var(--primary-color);
}
.HowUseBlock .BlockCard {
  background-color: var(--background-color);
}
.HowUseBlockContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  row-gap: 20px;
  column-gap: 50px;
  justify-items: center;
}
.Mobile .HowUseBlockContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
}
.HowUseItem {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 255px;
  height: 110px;
  background-color: var(--surface-color);
  box-shadow: 0px 10px 30px 0px var(--outline-color);
  justify-content: space-between;
}
.HowUseItem.Primary {
  background-color: var(--primary-color);
}
.HowUseItem.Primary .TitleMedium {
  color: var(--on-primary-color);
}
.HowUseItem .TitleMedium {
  font-weight: 500;
  height: fit-content;
  margin-top: 10px;
}
.HowUseItem .Icon {
  align-self: flex-end;
}
.HowUseItem.TopOffset {
  margin-top: 30px;
}
.Mobile .HowUseItem {
  width: 235px;
  height: 90px;
}
.Mobile .HowUseItem.TopOffset {
  margin-top: 0;
}
.SeamlessCoatingCalculatorBlock {
  background-color: var(--background-color);
}
.SeamlessCoatingCalculatorBlock .BlockCard {
  background-color: var(--surface-color);
}
.SeamlessCoatingCalculator {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 4px solid var(--primary-color);
  padding: 50px;
}
.SeamlessCoatingCalculator .TitleLarge {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.SeamlessCoatingCalculator .TitleLarge .Bold {
  margin-left: 10px;
  font-weight: 700;
}
.Mobile .SeamlessCoatingCalculator {
  border-width: 2px;
  padding: 25px;
}
.Mobile .SeamlessCoatingCalculator .TitleLarge {
  margin-top: 20px;
}
select,
input,
textarea {
  border-radius: 5px;
  border: 2px solid var(--primary-color);
  padding: 12px 32px;
  color: var(--primary-color);
  font-family: var(--text-font-family);
  color: var(--secondary-color);
  font-size: 18px;
  background-color: var(--surface-color);
}
select:focus,
input:focus,
textarea:focus {
  outline: none !important;
  border-color: var(--primary-color);
}
.Mobile select,
.Mobile input,
.Mobile textarea {
  font-size: 12px;
  padding-left: 15.5px;
  padding-right: 15.5px;
}
.SeamlessElements {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 50px;
  flex-wrap: wrap;
}
.Mobile .SeamlessElements {
  margin: 10px 0;
  row-gap: 10px;
  column-gap: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.Mobile .SeamlessElements p {
  min-width: 220px;
  width: 45%;
}
select {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../assets/icons/arrow-bottom.svg), linear-gradient(to right, var(--primary-color), var(--primary-color));
  background-position: calc(100% - 15px) 50%, 100% 0;
  background-size: 20px 20px, 50px 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.Mobile select {
  background-position: calc(100% - 15px) 50%, 100% 0;
  background-size: 20px 20px, 50px 100%;
}
.WarningInformation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  column-gap: 40px;
}
.WarningInformation .TitleSmall {
  flex: 1;
}
.Mobile .WarningInformation {
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 10px;
}
.FAQBlock {
  background-color: var(--surface-color);
}
.FAQBlock .BlockCard {
  background-color: var(--background-color);
}
.FAQBlockContent {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.Mobile .FAQBlockContent {
  row-gap: 20px;
}
.FAQItem {
  border-radius: 5px;
  border: 3px solid var(--primary-color);
  border-top-width: 0;
  border-bottom-width: 0;
}
.FAQItem.IsOpen {
  border-bottom-width: 3px;
}
.FAQItemHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--primary-color);
}
.FAQItemHeader .TitleMedium {
  color: var(--on-primary-color);
  padding: 20px 0px 20px 60px;
  height: fit-content;
  text-align: left;
  flex: 1;
}
.Mobile .FAQItemHeader .TitleMedium {
  padding: 10px 0px 10px 10px;
}
.FAQItemContent {
  padding: 20px 55px 20px 60px;
}
.FAQItemContent .TitleSmall {
  text-align: start;
}
.Mobile .FAQItemContent {
  padding: 10px;
}
.FAQItemOpenButton {
  width: 70px;
  aspect-ratio: 1;
  border-radius: 5px;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Mobile .FAQItemOpenButton {
  width: 55px;
  aspect-ratio: 1;
}
.Menu {
  background-image: url(../assets/icons/menu.svg);
  width: 24px;
  height: 24px;
}
.QuestionBlock {
  background-color: var(--background-color);
}
.QuestionBlock .BlockCard {
  background-color: var(--surface-color);
}
.QuestionBlock .BlockCard .DisplayLarge {
  margin-bottom: 0px;
}
.ContactForm {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.ContactForm .TitleMedium {
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: 500;
}
.Mobile .ContactForm {
  row-gap: 10px;
}
.Mobile .ContactForm .TitleMedium {
  font-size: 18px;
}
.FieldBar {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
}
.Mobile .FieldBar {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.Field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.Field .HeadlineMedium {
  margin-bottom: 10px;
}
.Field.Invalid input,
.Field.Invalid textarea {
  border-color: #B00020;
}
.Field.Invalid .HeadlineSmall {
  font-weight: 500;
  color: #B00020;
}
.Mobile .Field .HeadlineMedium {
  margin-bottom: 5px;
}
textarea {
  resize: none;
}
footer {
  background-color: var(--surface-color);
}
footer .BlockCard {
  background-color: var(--primary-color);
}
footer .BlockCardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 25px 0px 0px 0px;
}
.FooterContent {
  display: flex;
  justify-content: space-around;
  height: fit-content;
  align-items: end;
  padding: 25px 0px;
}
.FooterContent p {
  color: var(--on-primary-color);
}
.Mobile .FooterContent {
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ContactInfo {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 25px 0px;
  border: 0px solid var(--on-primary-color);
  border-top-width: 3px;
  background-color: var(--primary-color);
}
.Mobile .ContactInfo {
  padding: 10px 0px;
}
.Contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
}
.Contacts .Link {
  margin-left: 10px;
}
.Contacts .TitleMedium {
  color: var(--on-primary-color);
}
.Mobile .Contacts {
  row-gap: 10px;
}
.Link {
  text-decoration: underline;
}
.ModalizeBackground {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #00000088;
  cursor: auto;
}
.ModalizeCard {
  position: relative;
  background-color: var(--surface-color);
  border-radius: 20px;
  padding: 48px;
}
.ModalizeCard .PlatformOffset {
  width: 1040px;
}
.ModalizeCard .DisplaySmall {
  margin-right: 36px;
  margin-bottom: 10px;
}
.Mobile .ModalizeCard {
  padding: 24px;
}
.Mobile .ModalizeCard .FieldBar {
  margin-bottom: 10px;
}
.Mobile .ModalizeCard .DisplaySmall {
  margin-right: 12px;
  margin-bottom: 5px;
}
.ModalizeContent {
  width: 940px;
}
.Mobile .ModalizeContent {
  width: 100%;
}
.CatalogItemDetail {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
}
.CatalogItemDetail .BodyMedium {
  font-weight: 400;
}
.CatalogItemDetail .BodyMedium b {
  font-weight: 600;
}
.CatalogItemImageDetail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 940px;
  column-gap: 50px;
}
table {
  margin-top: 50px;
  border-spacing: 0px;
  width: 100%;
}
table thead tr {
  background-color: var(--primary-color);
}
table tr {
  height: 40px;
}
table tbody td {
  border: solid 1px var(--primary-color);
}
table tbody td:first-child {
  border-left-width: 2px;
}
table tbody td:last-child {
  border-right-width: 2px;
}
table tbody tr:last-child td {
  border-bottom-width: 2px;
}
table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}
table th,
table td {
  padding: 0;
  text-align: center;
}
table th.TitleMedium {
  color: var(--on-primary-color);
}
table th:first-child {
  border-top-left-radius: 5px;
  border-right: solid 1px var(--on-primary-color);
}
table th:last-child {
  border-top-right-radius: 5px;
  border-left: solid 1px var(--on-primary-color);
}
table td {
  border: solid 1px var(--primary-color);
}
table td.BodyMedium {
  font-weight: 400;
}
.Mobile table {
  margin-top: 20px;
}
.ModalizeBackgroundOffset {
  padding: 0 50px;
}
.Mobile .ModalizeBackgroundOffset {
  width: 100%;
  padding: 0 15px;
}
.ModalizeCloseButton {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
}
.ModalizeCloseButton img {
  width: 24px;
  height: 24px;
}
.Mobile .ModalizeCloseButton {
  top: 6px;
  right: 6px;
  width: 36px;
  height: 36px;
}
.Mobile .ModalizeCloseButton img {
  width: 18px;
  height: 18px;
}
.ModalizeContainer {
  position: fixed;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Modalize {
  position: absolute;
}
.Modalize .PlatformOffset {
  padding: 0px;
}
.Modalize .ContactForm .TitleMedium {
  color: var(--secondary-color);
  font-size: 18px;
  font-weight: 500;
}
.Mobile .Modalize {
  width: 100%;
}
.Mobile .Modalize .ContactForm .TitleMedium {
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 500;
}
.Loading {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #00000088;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.Loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 8px solid var(--primary-color);
  border-color: var(--primary-color) transparent var(--primary-color) transparent;
  animation: Loader 1.2s linear infinite;
}
@keyframes Loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.SnackbarContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}
.Snackbar {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color);
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 20px;
  width: fit-content;
  max-width: 75%;
  box-shadow: 0px -4px 30px 0px var(--shadow-color);
}
.Snackbar p {
  text-align: center;
}
.Mobile .Snackbar {
  padding: 15px;
}
.MobileMenuBackground {
  height: 100%;
  width: 100%;
  background-color: #00000088;
}
.MobileMenuContainer {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
}
.CloseIcon {
  background-image: url(../assets/icons/close.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
}
.MobileMenu {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.SectionsView {
  display: flex;
  flex-direction: column;
  background-color: var(--surface-color);
}
.SectionsView .SectionButton a {
  padding: 15px 10px;
  width: 100%;
}
.SectionsView .SectionButton .TitleMedium {
  text-align: start;
  line-height: normal;
}
